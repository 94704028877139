import { V3BlueprintTypes } from '@cango-app/types'
import _uniqBy from 'lodash/uniqBy'

import { PropogatedNode } from '../types'

// Threads
// 1. Have all parents of a task
// 2. Check connections to check route is correct
// 3. Loop upwards to find all threads

export const getThreads = ({
	nodeMap,
	nodeId,
	visitedNodes = new Set(),
}: {
	nodeMap: Map<string, PropogatedNode>
	nodeId: string
	visitedNodes?: Set<string>
}): V3BlueprintTypes.Thread[] => {
	const node = nodeMap.get(nodeId)
	if (!node || visitedNodes.has(nodeId)) return []
	visitedNodes.add(nodeId)
	const threads: V3BlueprintTypes.Thread[] = []
	const directParents = node.data.parents.reduce((_parents: PropogatedNode[], _parentId) => {
		const parent = nodeMap.get(_parentId)
		if (!parent) return _parents
		if (!parent.data.descendants.some((_desc) => _desc.step === nodeId)) {
			return _parents
		}
		_parents.push(parent)
		return _parents
	}, [])

	for (const _directParent of directParents) {
		_directParent.data.descendants.forEach((_desc) => {
			if (_desc.step !== nodeId) return
			if (_desc.database_chain_logic) {
				threads.push({
					color: '#c4def6',
					prefix: _desc.database_chain_logic.column,
					_id: _desc._id,
				})
				return
			}
			if (_desc.thread?._id) {
				threads.push({ ..._desc.thread, _id: _desc._id })
				return
			}
		})
		if (_directParent.data.isLastStepInChain) {
			continue
		}
		const parentOfDirectParentThreads = getThreads({
			nodeMap,
			nodeId: _directParent.data._id,
			visitedNodes,
		})
		threads.push(...parentOfDirectParentThreads)
	}

	return _uniqBy(threads, '_id')
}
