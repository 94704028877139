import React, { ComponentType, useContext, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { useDispatch } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'

import { Box, Button, Text } from 'src/components'
import type { RootState } from 'src/store/types'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'

type DeleteTaskProps = {
	onCloseDrawer?: () => void
}

export const DeleteTask: ComponentType<DeleteTaskProps> = ({ onCloseDrawer }) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [isDeleting, setIsDeleting] = useState(false)
	const { task } = useContext(TaskContext)

	const handleDeleteTask = async () => {
		if (!task || !task.section_id) return

		setIsDeleting(true)

		const response = await dispatch(
			projectActions.deleteTask({
				projectId: task.project_id,
				taskId: task._id,
			}),
		)
		setIsDeleting(false)
		if (response.meta.requestStatus === 'fulfilled' && onCloseDrawer) {
			onCloseDrawer()
		}
	}

	return (
		<>
			<Box m={1}>
				<IconButton onClick={onCloseDrawer}>
					<CloseOutlined />
				</IconButton>
			</Box>
			<Box width={400} p={3}>
				<Text variant="h6" mb={3}>
					Are you sure you want to delete: <b>{task?.name}</b>
				</Text>
				<Box mb={2}>
					<Button
						color="error"
						variant="contained"
						onClick={() => handleDeleteTask()}
						isLoading={isDeleting}
					>
						{'Delete'}
					</Button>
				</Box>
			</Box>
		</>
	)
}
