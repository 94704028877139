import React, { ComponentType, useContext, useEffect, useMemo, useState } from 'react'
import { ClientTypes } from '@cango-app/types'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { selectors as roleSelectors } from 'src/store/modules/roles'
import { Select, TextField, Toggle, Box, Text, Button } from 'src/components'
import { usePrevious } from 'src/hooks/usePrevious'
import { colors } from 'src/theme/colors'
import { CycleIcon } from 'src/assets/icons'

import { StepContext } from '../step-provider'
import { ChainContext } from '../../../chain-provider'
import { DependencyModal } from '../../chain-dependency-edge/dependency-modal'

import { StepFormType, getDefaultStepValues } from './step-form-container'

type CoreFieldsProps = {
	onSectionSelect: () => void
}

export const CoreFields: ComponentType<CoreFieldsProps> = ({ onSectionSelect }) => {
	const { watch, reset, setValue, control } = useFormContext<StepFormType>()
	const { step, parents } = useContext(StepContext)
	const { stepCycles, nodeMap, onUpdateConnection } = useContext(ChainContext)
	const roles = useSelector(roleSelectors.getRoles).filter((role) => role.internal)
	const [showDependencyModal, setShowDependencyModal] = useState<string>('')
	const [isSection, name] = watch(['isSection', 'name'])
	const previouslyWasSection = usePrevious(isSection)
	// const stepLoops = stepCycles.get(step?._id ?? '') ?? []

	const stepLoops = useMemo(() => {
		return [...stepCycles.values()].filter((_loop) => _loop.source === step?._id)
	}, [])

	const handleDeleteConnection = async (source: string, target: string) => {
		setShowDependencyModal('')
		onUpdateConnection({
			connection: { source, target },
			method: 'remove',
			createForEveryOption: false,
			thread: null,
			databaseLogic: null,
			option_condition: undefined,
			multiUseConfig: undefined,
		})
	}

	useEffect(() => {
		if (previouslyWasSection !== undefined && !previouslyWasSection && isSection) {
			reset({ ...getDefaultStepValues(step), name })
			setValue('isSection', true, { shouldDirty: true })
			onSectionSelect()
		}
		if (isSection) {
			onSectionSelect()
		}
	}, [isSection])

	return (
		<Box mt={2}>
			<Controller
				control={control}
				name="isSection"
				render={({ field: { value, onChange } }) => (
					<Toggle
						onChange={onChange}
						value={value}
						options={[
							{ value: false, label: 'Task' },
							{ value: true, label: 'Section' },
						]}
						containerProps={{ mb: 2 }}
					/>
				)}
			/>

			<Controller
				control={control}
				name="name"
				rules={{ required: 'Step name is required' }}
				render={({ field, fieldState: { error } }) => (
					<TextField
						error={!!error}
						label={`${isSection ? 'Section' : 'Step'} name`}
						{...field}
						required
						fullWidth
						containerProps={{ mb: 2, mt: 1 }}
					/>
				)}
			/>

			{!isSection && (
				<Controller
					control={control}
					name="description"
					render={({ field, fieldState: { error } }) => (
						<TextField
							error={!!error}
							multiline
							label="Description (optional)"
							{...field}
							fullWidth
							sx={{ mb: 2, mt: 1 }}
						/>
					)}
				/>
			)}

			{!isSection && (
				<Controller
					control={control}
					name="roles"
					rules={{
						validate: {
							hasRoles: (value) => value.length > 0 || 'At least one role is required',
						},
					}}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Select
							error={!!error}
							multiple
							label="Who should do this?"
							onChange={(event) => onChange(event.target.value as ClientTypes.Role)}
							value={value}
							options={roles}
							sx={{ mb: 2, mt: 1 }}
						/>
					)}
				/>
			)}

			{!isSection && (
				<Controller
					control={control}
					name="isMultiUse"
					render={({ field: { value, onChange } }) => (
						<Toggle
							options={[
								{ label: 'Once', value: false },
								{ label: 'Multiple times', value: true },
							]}
							label="How many times can this task be used?"
							value={value}
							onChange={onChange}
							containerProps={{ mb: 3 }}
						/>
					)}
				/>
			)}

			{parents.length > 1 && (
				<Controller
					control={control}
					name="requiresEveryParent"
					render={({ field: { value, onChange } }) => (
						<Toggle
							options={[
								{ label: 'Any', value: false },
								{ label: 'All', value: true },
							]}
							label="This task should appear when ANY or ALL of the parent conditions are met?"
							value={value}
							onChange={onChange}
							containerProps={{ mb: 3 }}
							formControlProps={{
								helperText:
									'If you select "All", this task will wait until all parents are completed. If you select Any, this task will be created every time any of its parents are completed.',
							}}
						/>
					)}
				/>
			)}

			{!!stepLoops.length && !!step && (
				<Box>
					<Text fontSize={14} color={colors.neutral['80']} fontWeight={500} mb={1}>
						Loops
					</Text>
					{stepLoops.map((_loop) => {
						const targetNode = nodeMap.get(_loop.target)
						const targetChild = step.descendants.find((_desc) => _desc.step === _loop.target)
						if (!targetNode || !targetChild) return null
						return (
							<Box key={_loop.id}>
								{showDependencyModal === _loop.target && (
									<DependencyModal
										options={step.options}
										isMenu={step.isMenu}
										onClose={() => setShowDependencyModal('')}
										source={_loop.source}
										target={_loop.target}
										onDeleteConnection={() => handleDeleteConnection(_loop.source, _loop.target)}
										child={targetChild}
									/>
								)}
								<Button
									variant="outlined"
									startIcon={<CycleIcon width={16} />}
									onClick={() => setShowDependencyModal(_loop.target)}
								>
									{nodeMap.get(_loop.target)?.data.name}
								</Button>
							</Box>
						)
					})}
				</Box>
			)}
		</Box>
	)
}
