import React, { ComponentType, useContext, useState } from 'react'
import { Collapse } from '@mui/material'

import { AttachedFile, Box, Button } from 'src/components'
import { DriveFilesContext } from 'src/providers'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'
import { colors } from 'src/theme/colors'
import { ChevronDown } from 'src/assets/icons'

type TaskFileHistoryProps = {
	file_history: string[]
	onRemoveFileFromTask?: (fileId: string) => void
}

export const TaskFileHistory: ComponentType<TaskFileHistoryProps> = ({
	file_history,
	onRemoveFileFromTask,
}) => {
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const [showFiles, setShowFiles] = useState(false)
	const { allFiles } = useParentFolderFiles(parentFolderId as string, parentFolderName as string)

	return (
		<Box
			sx={{
				border: showFiles ? '1px solid' : 'none',
				borderColor: colors.neutral['30'],
				borderRadius: '8px',
				p: showFiles ? 1 : 0,
				mb: 1,
			}}
		>
			<>
				<Button
					endIcon={
						<ChevronDown
							style={{
								transform: showFiles ? 'rotate(180deg)' : undefined,
							}}
						/>
					}
					variant="outlined"
					size="small"
					onClick={() => setShowFiles(!showFiles)}
				>
					{showFiles ? 'Hide' : 'Show'} file history
				</Button>
				<Collapse in={showFiles} unmountOnExit>
					<Box display="flex" flexWrap="wrap" mt={1}>
						{file_history.map((fileId) => {
							const file = allFiles.find((file) => file.id === fileId)
							return (
								<AttachedFile
									containerStyles={{ mb: 1 }}
									key={`historical-${fileId}`}
									fileId={fileId}
									_file={file}
									parentFolderId={parentFolderId}
									parentFolderName={parentFolderName}
									onRemoveFileFromTask={
										onRemoveFileFromTask ? () => onRemoveFileFromTask(fileId) : undefined
									}
								/>
							)
						})}
					</Box>
				</Collapse>
			</>
		</Box>
	)
}
