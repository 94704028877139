import { useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import dayjs from 'dayjs'

import { selectors as projectSelectors, ProjectsLoadingState } from 'src/store/modules/projects-v3'
import { Box } from 'src/components'

import { useProject } from '../useProject'
import { RootState } from '../../../store/types'

import SectionTasks from './section-tasks'

export const ProjectTasks = () => {
	const { goToTask } = useProject()
	const projectTasksState = useSelector(projectSelectors.getProjectTasksState)

	const projectSections = useSelector((state: RootState) =>
		projectSelectors.getProjectSectionsWithTasksSelector(state, true),
	)

	const isFetchingTasks = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		projectTasksState,
	)
	return (
		<>
			{isFetchingTasks && (
				<Box mx={4} mt={4}>
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
				</Box>
			)}

			{!isFetchingTasks && !!projectSections.length && (
				<Box mx={4} mt={4}>
					{projectSections.map((section) => (
						<Box key={section._id} id={section._id}>
							<SectionTasks section={section} onEditTask={goToTask} />
						</Box>
					))}
				</Box>
			)}
		</>
	)
}
