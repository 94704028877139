import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useContext } from 'react'

import { Text } from 'src/components'
import { TemplatesContext } from 'src/providers/templates-provider'
import { TemplatesEditorForm } from 'src/modules/templates-editor/types'

import { Tiptap } from './tiptap-editor'
import { VariablesManager } from './variables-manager'
import { EditorProvider } from './useRichEditor'

export const TemplatesEditor = () => {
	const { selectedTemplate } = useContext(TemplatesContext)
	const methods = useForm<TemplatesEditorForm>({
		defaultValues: {
			variables: selectedTemplate?.variables ?? [],
			content: selectedTemplate?.content ?? '',
		},
	})

	return (
		<FormProvider {...methods}>
			<EditorProvider defaultContent={selectedTemplate?.content}>
				<Stack direction="column" spacing={1} padding={2} width="100%">
					<Text variant="h4">{selectedTemplate?.name}</Text>
					<Accordion>
						<AccordionSummary>
							<Text variant="h5">Variables Manager</Text>
						</AccordionSummary>
						<AccordionDetails>
							<VariablesManager />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={true}>
						<AccordionSummary>
							<Text variant="h5">Template Editor</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Tiptap />
						</AccordionDetails>
					</Accordion>
				</Stack>
			</EditorProvider>
		</FormProvider>
	)
}
