import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import ReactFlowLibrary, {
	Controls,
	Background,
	useReactFlow,
	useNodesState,
	useEdgesState,
	ReactFlowProvider,
	MiniMap,
	Connection,
	Node,
	getOutgoers,
} from 'reactflow'

import { Box, Button, Grid, Text } from 'src/components'
import { SettingsCogIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { usePrevious } from 'src/hooks/usePrevious'
import { DriveFilesProvider } from 'src/providers'
import { TableProvider } from 'src/providers/table-provider'
import { TemplatesProvider } from 'src/providers/templates-provider'

import { LoopNode } from './components/loop-node'
import StandardNode from './components/standard-node'
import { ChainContext } from './chain-provider'
import { ChainDependencyEdge } from './components/chain-dependency-edge'
import ChainNode from './components/chain-node'
import { BlueprintOpsDrawer } from './components/blueprint-ops/blueprint-ops-drawer'
import 'reactflow/dist/style.css'
import { getLayoutedElements } from './get-element-layout'
import { CangoChainNode } from './types'

// 1 - add last step on chain - ✅
// 2 - add step after chain
// 3 - show multiple strands of chain
// 4 - single select chains - ✅

const nodeTypes = { standard: StandardNode, chainReference: ChainNode, loop: LoopNode }
const edgeTypes = {
	chainOptionsEdge: ChainDependencyEdge,
}

const LayoutFlow: ComponentType = () => {
	const {
		steps,
		onUpdateConnection,
		setNodes: setContextNodes,
		setAdjacencyList,
		setStepCycles,
		chain,
	} = useContext(ChainContext)
	const { fitView, getNodes, getEdges } = useReactFlow()
	const [nodes, setNodes, onNodesChange] = useNodesState([])
	const previousNodesLength = usePrevious(nodes.length)
	const [edges, setEdges, onEdgesChange] = useEdgesState([])
	// const [selectedStep, setSelectedStep] = useState<string | undefined>(undefined)

	const onLayout = () => {
		const layouted = getLayoutedElements(steps, { fitView }, chain?.begins_with ?? [])

		setNodes([...layouted.nodes] as CangoChainNode[])
		setEdges([...layouted.edges])
		setContextNodes([...layouted.nodes] as CangoChainNode[])
		setAdjacencyList(layouted.adjacencyList)
		setStepCycles(layouted.cycles)
	}

	const isValidConnection = useCallback(
		(connection: Connection) => {
			// we are using getNodes and getEdges helpers here
			// to make sure we create isValidConnection function only once
			const nodes = getNodes()
			const edges = getEdges()
			const target = nodes.find((node) => node.id === connection.target)
			if (!target) return false
			const hasCycle = (node: Node, visited = new Set()) => {
				if (visited.has(node.id)) return false

				visited.add(node.id)

				for (const outgoer of getOutgoers(node, nodes, edges)) {
					if (outgoer.id === connection.source) return true
					if (hasCycle(outgoer, visited)) return true
				}
			}

			if (target.id === connection.source) return false
			return !hasCycle(target)
		},
		[getNodes, getEdges],
	)

	useEffect(() => {
		if (previousNodesLength !== undefined && previousNodesLength === 0 && nodes.length) {
			fitView({ nodes: [nodes[0]], maxZoom: 1 })
		}
	}, [previousNodesLength, nodes.length])

	useEffect(() => {
		onLayout()
	}, [steps])

	return (
		<>
			<ReactFlowLibrary
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				nodesDraggable={false}
				isValidConnection={isValidConnection}
				onConnect={(connection) =>
					onUpdateConnection({
						connection,
						method: 'add',
						createForEveryOption: false,
						thread: null,
						databaseLogic: null,
						option_condition: undefined,
						multiUseConfig: undefined,
					})
				}
				onEdgesDelete={(connections) => {
					connections.forEach((connection) =>
						onUpdateConnection({
							connection: {
								...connection,
								target: connection.data.child.step,
							},
							method: 'remove',
							createForEveryOption: false,
							thread: null,
							databaseLogic: null,
							option_condition: undefined,
							multiUseConfig: undefined,
						}),
					)
				}}
			>
				<Background />
				<Controls />
				<MiniMap pannable />
			</ReactFlowLibrary>
		</>
	)
}

export const ChainFlow: ComponentType = () => {
	const { chain } = useContext(ChainContext)
	const [isBlueprintOpsDrawerOpen, setIsBlueprintOpsDrawerOpen] = useState(false)

	if (!chain) {
		return null
	}

	return (
		<TableProvider tableId={chain.database_table}>
			<TemplatesProvider>
				<DriveFilesProvider
					parentFolderId={chain.google_drive_folder_id}
					parentFolderName={chain.name}
				>
					<BlueprintOpsDrawer
						open={isBlueprintOpsDrawerOpen}
						onClose={() => setIsBlueprintOpsDrawerOpen(false)}
					/>
					<Box flex={1} display="flex" flexDirection="column">
						<Grid
							container
							height={50}
							bgcolor="#fff"
							boxShadow={3}
							display="flex"
							alignItems="center"
							px={3}
						>
							<Grid item xs={3}></Grid>
							<Grid item xs={6}>
								<Text fontWeight="bold" textAlign="center">
									{chain.name}
								</Text>
							</Grid>
							<Grid item xs={3} display="flex" alignItems="center" justifyContent="flex-end">
								<Button
									onClick={() => setIsBlueprintOpsDrawerOpen(true)}
									size="small"
									variant="outlined"
									startIcon={<SettingsCogIcon stroke={colors.feldgrau['60']} width={18} />}
								>
									Blueprint settings
								</Button>
							</Grid>
						</Grid>
						<ReactFlowProvider>
							<Box
								flex={1}
								sx={
									{
										// '.react-flow__node': {
										// 	zIndex: '-1 !important',
										// },
									}
								}
							>
								<LayoutFlow />
							</Box>
						</ReactFlowProvider>
					</Box>
				</DriveFilesProvider>
			</TemplatesProvider>
		</TableProvider>
	)
}
