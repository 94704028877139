import React, { ComponentType, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { V3BlueprintTypes } from '@cango-app/types'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import { Box, Text, Notes } from 'src/components'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { actions as projectsActions } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import { TabPanel } from 'src/components/tab-panel'
import { ChainList } from 'src/components/section-tasks-v3/chains-list'

import { NameLabel } from '../../section-tasks/name-label'
import { Annotations } from '../annotations'

import { TaskInfo } from './task-info'
import { TaskTraining } from './task-training'
import { TaskSettingsPanel } from './settings'
import TaskHierarchy from './task-hierarchy/index'
import { TaskReport } from './task-report'

type TaskFormProps = {
	onDelete: () => void
	parentId?: string
	setIsUpdating: (shouldUpdate: boolean) => void
	onRevertInstance: (instanceId: string, sectionId: string) => Promise<void>
}

export enum TaskDrawerTab {
	Info = 0,
	Annotations = 1,
	Training = 2,
	Lifecycle = 3,
	TaskInbox = 4,
	Settings = 5,
	Hierarchy = 6,
	ReportTask = 7,
}

export const TaskFormContainer: ComponentType<TaskFormProps> = ({ onDelete, parentId }) => {
	const dispatch = useDispatch()
	const { task, isLoadingTask } = useContext(TaskContext)
	const [activeTab, setActiveTab] = useState<TaskDrawerTab>(TaskDrawerTab.Info)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)

	if (!task || isLoadingTask) {
		return (
			<Box flex={1} display="flex" justifyContent="center" alignItems="center" height="100%">
				<PulseLoader size={8} />
			</Box>
		)
	}

	return (
		<>
			<Text variant="h4">
				{task.name ? (
					<NameLabel name={task.name} isTitle={true} />
				) : parentId ? (
					'New subtask'
				) : (
					'New task'
				)}
			</Text>
			<Box>
				<Text fontStyle="italic">
					owned by{' '}
					{task.roles
						.map((_role) => mappedRoles.get(_role)?.label)
						.filter(Boolean)
						.join(', ')}
				</Text>
			</Box>
			<Box py={1}>
				<ChainList chain={task.chain} />
			</Box>
			{!task.assignees?.length && (
				<Box mt={2}>
					<Alert severity="error">
						<AlertTitle>This task is not assigned to anybody</AlertTitle>
						Assign a user from the settings tab
					</Alert>
				</Box>
			)}
			{!!task.description && (
				<Box my={2}>
					<Alert severity="info">{task?.description}</Alert>
				</Box>
			)}

			<Tabs
				variant="scrollable"
				value={activeTab}
				sx={{ flex: 1 }}
				onChange={(e, newValue) => setActiveTab(newValue)}
			>
				<Tab value={TaskDrawerTab.Info} label="Info" />
				<Tab value={TaskDrawerTab.Annotations} label="Notes" />
				{task.training.length ? (
					<Tab value={TaskDrawerTab.Training} label="Training" disabled={!task.training.length} />
				) : null}
				<Tab value={TaskDrawerTab.TaskInbox} label="Task inbox" />
				<Tab value={TaskDrawerTab.Hierarchy} label="History" />
				<Tab value={TaskDrawerTab.Settings} label="Settings" />
				<Tab value={TaskDrawerTab.ReportTask} label="Report" />
			</Tabs>
			<TaskInfo activeTab={activeTab} />
			<TaskTraining task={task} index={TaskDrawerTab.Training} activeValue={activeTab} />
			<TabPanel index={TaskDrawerTab.TaskInbox} value={activeTab} containerProps={{ pt: 2 }}>
				<Notes />
			</TabPanel>
			<TabPanel value={activeTab} index={TaskDrawerTab.Hierarchy} containerProps={{ py: 2 }}>
				<TaskHierarchy onResetTaskComplete={(data) => dispatch(projectsActions.resetTask(data))} />
			</TabPanel>

			<TabPanel index={TaskDrawerTab.Settings} value={activeTab} containerProps={{ pt: 2 }}>
				<TaskSettingsPanel onDelete={onDelete} />
			</TabPanel>
			<TabPanel index={TaskDrawerTab.ReportTask} value={activeTab}>
				<TaskReport />
			</TabPanel>
			<TabPanel index={TaskDrawerTab.Annotations} value={activeTab}>
				<Annotations />
			</TabPanel>
		</>
	)
}
