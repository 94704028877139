import { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import LinearProgress from '@mui/material/LinearProgress'

import { Box, Button, Grid, Text } from 'src/components'
import { selectors as projectSelectors, ProjectsLoadingState } from 'src/store/modules/projects-v3'
import { UniversalDrawerType, actions as configActions } from 'src/store/modules/config'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RootState } from 'src/store/types'
import { TaskProvider } from 'src/providers/task-provider'
import { ChainList } from 'src/components/section-tasks-v3/chains-list'

import { SingleTaskComponent } from './single-task-container'
import { TaskList } from './task-list'
import { AllMyTasksDone } from './all-my-task-done'

export const DesktopSectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const { selectedSectionId, selectedTaskId } = useMyTasks()
	const section = useSelector((state: RootState) =>
		projectSelectors.getProjectSection(state, selectedSectionId),
	)
	const selectedTask = useSelector((state: RootState) =>
		projectSelectors.getSelectedTask(state, selectedTaskId),
	)
	const projectState = useSelector(projectSelectors.getProjectLoadingState)
	const totalFilesRequiringUpload = useSelector(
		projectSelectors.getTotalNumberOfFilesRequiringUpload,
	)
	const numberOfFilesPendingUpload = useSelector(projectSelectors.getNumberOfFilesPendingUpload)
	const taskFilesUploadedSize = useSelector(projectSelectors.getPercentageOfTasksUploaded)
	const [isLoading, setIsLoading] = useState(false)

	const handleNewProjectClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Project))
	}
	const isProjectLoading = [ProjectsLoadingState.Loading, ProjectsLoadingState.Idle].includes(
		projectState,
	)

	return (
		<TaskProvider taskId={selectedTaskId} onTaskLoading={setIsLoading}>
			<Box flex={3} ml={{ laptop: 3 }} height="100%" sx={{ overflowY: 'auto' }}>
				<Grid container my={2}>
					<Grid item xs={10}>
						<ChainList chain={selectedTask?.chain} />
					</Grid>
					<Grid item xs={2}>
						<Box>
							<Button onClick={handleNewProjectClick}>New project</Button>
						</Box>
					</Grid>
				</Grid>
				{isProjectLoading && (
					<Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '30px' }} />
				)}
				{!section && !isProjectLoading && (
					<AllMyTasksDone bgcolor="white" borderRadius="30px" px={3} py={2} height={400} />
				)}
				{section && !isProjectLoading && (
					<Box bgcolor="white" borderRadius="30px" px={3} py={2} minHeight={{ laptop: 200 }}>
						<Box>
							<Box height="4px">
								{isLoading && <LinearProgress variant="indeterminate" />}
								{taskFilesUploadedSize !== 100 && (
									<>
										<LinearProgress variant="determinate" value={taskFilesUploadedSize} />
										<Text fontSize={12} textAlign="center">
											{'Uploading '}
											{totalFilesRequiringUpload - numberOfFilesPendingUpload + 1}
											{' of '}
											{totalFilesRequiringUpload}
										</Text>
									</>
								)}
							</Box>
							{selectedTask ? <SingleTaskComponent /> : <TaskList />}
						</Box>
					</Box>
				)}
			</Box>
		</TaskProvider>
	)
}
