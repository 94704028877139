import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { EdgeProps, BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow'
import { Badge, Stack } from '@mui/material'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'
import { V3BlueprintTypes } from '@cango-app/types'

import { ChainIcon, CheckSquare, DatabaseIcon, PlusIcon, SingleOptionIcon } from 'src/assets/icons'
import { Box, IconButton, Text } from 'src/components'
import { colors } from 'src/theme/colors'
import { TableContext } from 'src/providers/table-provider'
import { useListOptions } from 'src/hooks/use-list-options'

import { ChainContext } from '../../chain-provider'
import { EdgeData } from '../../types'

import { OptionListItem } from './dependency-option-list-item'
import { DependencyModal } from './dependency-modal'

export const ChainDependencyEdgeContainer: ComponentType<EdgeProps<EdgeData>> = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	data,
	source,
	target,
}) => {
	const { onUpdateConnection, nodeMap } = useContext(ChainContext)
	const { mappedColumns } = useContext(TableContext)
	const [showSettingsModal, setShowSettingsModal] = useState(false)
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		targetX,
		targetY,
	})
	const sourceNode = nodeMap.get(source)
	const fromNode = nodeMap.get(data?.child.option_condition?.from ?? '')
	const listOptions = useListOptions(fromNode?.data.options ?? data?.options ?? [])

	const handleDeleteConnection = () => {
		if (!data?.child.step) {
			return
		}
		setShowSettingsModal(false)
		onUpdateConnection({
			connection: { source, target: data?.child.step },
			method: 'remove',
			createForEveryOption: false,
			thread: null,
			databaseLogic: null,
			option_condition: undefined,
			multiUseConfig: undefined,
		})
	}

	const connectionVariant = useMemo(() => {
		if (!data?.child?.option_condition) {
			return 'complete'
		}

		if (data.child.createForEveryOption) {
			return 'createForEveryOption'
		}

		return 'selectedOptions'
	}, [data])

	const backgroundColor = useMemo(() => {
		if (!connectionVariant) {
			return '#fff'
		}

		if (connectionVariant === 'complete' && !data?.child?.option_condition) {
			return colors.feldgrau['20']
		}

		return colors.sunglow['20']
	}, [connectionVariant, data?.nextStep])

	const fontColor = useMemo(() => {
		if (!connectionVariant) {
			return colors.neutral['80']
		}

		if (connectionVariant === 'complete' && !data?.child?.option_condition) {
			return colors.feldgrau['80']
		}

		return colors.feldgrau['60']
	}, [connectionVariant, data?.nextStep])

	const selectedOptions = useMemo(() => {
		if (data?.isMenu) {
			return {
				label: 'create one chain for selected options',
			}
		}
		const optionConditionValues = data?.child?.option_condition?.values ?? []
		if (optionConditionValues.length) {
			const foundOption = listOptions.find(
				(_listOption) => _listOption._id === optionConditionValues[0],
			)
			return {
				label: foundOption?.label ?? '',
				dueTime: foundOption?.dueTime,
			}
		}
		return {
			label: '',
		}
	}, [data, listOptions])

	return (
		<>
			{showSettingsModal && data && (
				<DependencyModal
					options={data.options}
					isMenu={data.isMenu}
					onClose={() => setShowSettingsModal(false)}
					source={source}
					target={target}
					onDeleteConnection={handleDeleteConnection}
					child={data.child}
				/>
			)}
			<BaseEdge id={id} path={edgePath} />
			<EdgeLabelRenderer>
				{!sourceNode?.data.isSection || connectionVariant !== 'complete' ? (
					<Box
						sx={{
							position: 'absolute',
							transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
							pointerEvents: 'all',
							bgcolor: backgroundColor,
							maxWidth: 100,
							borderRadius: 1,
							color: fontColor,
						}}
						onClick={() => setShowSettingsModal(true)}
					>
						<Badge
							color="primary"
							badgeContent={
								<Stack direction="row" alignItems="center">
									{selectedOptions.dueTime?.when === V3BlueprintTypes.WhenDueTime.before ? (
										<HistoryOutlinedIcon sx={{ fontSize: 12 }} />
									) : (
										<UpdateOutlinedIcon sx={{ fontSize: 12 }} />
									)}
									<Text variant="caption">{selectedOptions?.dueTime?.time}</Text>
								</Stack>
							}
							invisible={!selectedOptions?.dueTime}
						>
							<Box padding={1}>
								{connectionVariant === 'complete' && (
									<OptionListItem
										icon={<CheckSquare stroke={colors.feldgrau['80']} width={12} />}
										label="on complete"
										// secondaryLabel={
										// 	data.nextStep.thread?.option_conditions.length
										// 		? `${data.nextStep.thread?.option_conditions.length} selections`
										// 		: undefined
										// }
									/>
								)}
								{connectionVariant === 'createForEveryOption' && (
									<OptionListItem
										icon={<ChainIcon fill={colors.feldgrau['40']} />}
										label="create a chain for every option"
									/>
								)}
								{connectionVariant === 'selectedOptions' && (
									<OptionListItem
										icon={
											<SingleOptionIcon
												stroke={colors.feldgrau['40']}
												style={{ transform: 'rotate(180deg)' }}
											/>
										}
										label={selectedOptions.label}
									/>
								)}
								{(!!data?.child?.database_chain_logic?.column ||
									typeof data?.options === 'string') && (
									<Box mt={1}>
										<OptionListItem
											icon={<DatabaseIcon stroke={colors.feldgrau['40']} width={12} />}
											label="Database logic"
											secondaryLabel={
												mappedColumns.get(
													data.child?.database_chain_logic?.column ?? String(data.options) ?? '',
												)?.name ?? ''
											}
										/>
									</Box>
								)}
							</Box>
						</Badge>
					</Box>
				) : (
					<Box
						sx={{
							position: 'absolute',
							transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
							pointerEvents: 'all',
							maxWidth: 100,
							p: 1,
							color: fontColor,
						}}
					>
						<IconButton
							size="small"
							sx={{
								bgcolor: colors.neutral['30'],
								'&:hover': {
									bgcolor: colors.sunglow['20'],
								},
							}}
							onClick={() => setShowSettingsModal(true)}
						>
							<PlusIcon width={16} stroke={colors.feldgrau['60']} />
						</IconButton>
					</Box>
				)}
			</EdgeLabelRenderer>
		</>
	)
}
