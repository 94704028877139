import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactFlowLibrary, {
	Background,
	ReactFlowProvider,
	Controls,
	MiniMap,
	useNodesState,
	useEdgesState,
	Edge,
	Connection,
	useReactFlow,
	getOutgoers,
	Node,
} from 'reactflow'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { Box } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { colors } from 'src/theme/colors'

import { BasicQuestionaireData, CangoQuestionaireNode } from './types'
import { getLayout } from './get-layout'
import { NodeModal } from './node-modal'
import { QuestionNode } from './question-node'

const nodeTypes = { question: QuestionNode }

const LayoutFlow: ComponentType = () => {
	const { table, updateDecendants, onDeleteRecords } = useContext(TableContext)
	const [nodes, setNodes, onNodesChange] = useNodesState([])
	const [edges, setEdges, onEdgesChange] = useEdgesState([])
	const { getNodes, getEdges } = useReactFlow()
	const [selectedNode, setSelectedNode] = useState<TableTypes.Record | undefined>(undefined)

	const questionColumnId = table?.principal_field ?? ''
	const optionsColumnId = useMemo(() => {
		return table?.fields.find((_field) => _field.type === TableTypes.FieldType.OPTIONS)?._id ?? ''
	}, [table?.fields])

	const onLayout = () => {
		const layout = getLayout({ rows: table?.records ?? [], questionColumnId, optionsColumnId })

		setNodes([...layout.nodes] as CangoQuestionaireNode<BasicQuestionaireData>[])
		setEdges([...layout.edges])
	}

	const handleDeleteConnection = useCallback(
		async (edges: Edge[]) => {
			const edge = edges[0]
			const originalNode = table?.records.find((_record) => _record._id === edge.source)
			if (!originalNode) {
				return
			}
			const newDecendants =
				originalNode.descendants?.filter((_desc) => _desc.row !== edge.target) ?? []
			await updateDecendants({ rowId: originalNode._id, descendants: newDecendants })
		},
		[table?.records],
	)

	const handleDeletenode = useCallback(
		async (nodes: Node[]) => {
			await onDeleteRecords(new Map(nodes.map((node) => [node.id, {}])))
		},
		[table?.records],
	)

	const handleAddConnection = useCallback(
		async (connection: Connection) => {
			const originalNode = table?.records.find((_record) => _record._id === connection.source)
			if (!originalNode || !connection.target) {
				return
			}
			const newDecendants = [
				...(originalNode.descendants ?? []),
				{ row: connection.target, _id: v4() },
			]
			await updateDecendants({ rowId: originalNode._id, descendants: newDecendants })
		},
		[table?.records],
	)

	const isValidConnection = useCallback(
		(connection: Connection) => {
			// we are using getNodes and getEdges helpers here
			// to make sure we create isValidConnection function only once
			const nodes = getNodes()
			const edges = getEdges()
			const target = nodes.find((node) => node.id === connection.target)
			if (!target) return false
			const hasCycle = (node: Node, visited = new Set()) => {
				if (visited.has(node.id)) return false

				visited.add(node.id)

				for (const outgoer of getOutgoers(node, nodes, edges)) {
					if (outgoer.id === connection.source) return true
					if (hasCycle(outgoer, visited)) return true
				}
			}

			if (target.id === connection.source) return false
			return !hasCycle(target)
		},
		[getNodes, getEdges],
	)

	useEffect(() => {
		onLayout()
	}, [table?.records])

	return (
		<>
			{selectedNode && (
				<NodeModal
					node={selectedNode}
					questionColumnId={questionColumnId}
					optionsColumnId={optionsColumnId}
					onClose={() => setSelectedNode(undefined)}
					allNodes={nodes}
				/>
			)}
			<ReactFlowLibrary
				nodes={nodes}
				edges={edges}
				onEdgesDelete={handleDeleteConnection}
				onNodesDelete={handleDeletenode}
				isValidConnection={isValidConnection}
				onConnect={handleAddConnection}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeTypes}
				nodesDraggable={false}
				onNodeClick={(event, _node) => {
					const node = table?.records.find((_record) => _record._id === _node.id)
					setSelectedNode(node)
				}}
			>
				<Background />
				<Controls />
				<MiniMap pannable />
			</ReactFlowLibrary>
		</>
	)
}

const QuestionaireContainer: ComponentType = () => {
	return (
		<ReactFlowProvider>
			<Box flex={1} border="3px solid" borderRadius="8px" borderColor={colors.neutral['40']}>
				<LayoutFlow />
			</Box>
		</ReactFlowProvider>
	)
}

export default QuestionaireContainer
