import { List, Stack, ListItem, ListItemText, ListItemButton, ListItemIcon } from '@mui/material'
import { ComponentType, useCallback, useContext, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { TemplateTypes } from '@cango-app/types'
import { PulseLoader } from 'react-spinners'

import { Box, Button, IconButton, Modal, Text, TextField } from 'src/components'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'
import {
	CircledCheckIcon,
	CloseIcon,
	EditPenIcon,
	TemplatesIcon,
	TrashIcon,
} from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { useKeypress } from 'src/hooks/useKeyPress'
import { TemplatesContext } from 'src/providers/templates-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'

const TemplateListItem = ({
	isEditMode,
	setTemplateOnEditMode,
	name,
	...template
}: TemplateTypes.Template & {
	isEditMode: boolean
	setTemplateOnEditMode: React.Dispatch<React.SetStateAction<null | string>>
}) => {
	const [templateName, setTemplateName] = useState(name)
	const { onUpdateTemplate, onDeleteTemplate, isLoadingTemplates, setSelectedTemplate, templates } =
		useContext(TemplatesContext)

	const handleUpdateTemplate = useCallback(async () => {
		if (isEditMode) {
			const { isSuccess } = await onUpdateTemplate({
				...template,
				name: templateName,
			})
			if (isSuccess) {
				setTemplateOnEditMode(null)
			}
		}
	}, [onUpdateTemplate, template, templateName, isEditMode])
	useKeypress('Enter', handleUpdateTemplate)

	if (isEditMode) {
		return (
			<Box display="flex" width="100%" alignItems="center">
				<TextField
					fullWidth
					value={templateName}
					onChange={(e) => setTemplateName(e.target.value)}
					containerProps={{ flex: 1 }}
					disabled={isLoadingTemplates}
				/>
				{isLoadingTemplates ? (
					<PulseLoader size={4} />
				) : (
					<>
						<IconButton onClick={handleUpdateTemplate}>
							<CircledCheckIcon />
						</IconButton>
						<IconButton onClick={() => onDeleteTemplate(template._id)}>
							<TrashIcon />
						</IconButton>
						<IconButton onClick={() => setTemplateOnEditMode(null)}>
							<CloseIcon />
						</IconButton>
					</>
				)}
			</Box>
		)
	}

	return (
		<ListItem disableGutters>
			<ListItemButton
				sx={{ borderRadius: 2 }}
				onClick={() => {
					const selectedTemplate = templates.find(({ _id }) => template._id === _id)
					if (!selectedTemplate) {
						showSnackbar('Error selecting template', { variant: 'error' })
					}
					setSelectedTemplate(selectedTemplate)
				}}
			>
				<>
					<ListItemIcon>
						<TemplatesIcon />
					</ListItemIcon>
					<ListItemText primary={name} />
					<IconButton
						onClick={(e) => {
							e.stopPropagation()
							setTemplateOnEditMode(template._id)
						}}
					>
						<EditPenIcon stroke={colors.feldgrau['60']} width={16} />
					</IconButton>
				</>
			</ListItemButton>
		</ListItem>
	)
}

type Props = {
	columnWidth: number
}

export const TemplatesMenu: ComponentType<Props> = ({ columnWidth }) => {
	const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false)
	const [templateOnEditMode, setTemplateOnEditMode] = useState<string | null>(null)
	const [templateName, setTemplateName] = useState('')
	const { templates, onCreateTemplate, isLoadingTemplates } = useContext(TemplatesContext)
	useKeypress('Escape', () => setTemplateOnEditMode(null))

	const handleClose = useCallback(() => {
		setIsCreateTemplateOpen(false)
		setTemplateName('')
	}, [])
	return (
		<>
			<Modal open={isCreateTemplateOpen} onClose={handleClose}>
				<Stack direction="column" spacing={2} width="300px">
					<Text variant="h6"> Create Template</Text>
					<TextField
						fullWidth
						label="Name"
						placeholder="Template 1"
						value={templateName}
						onChange={(e) => setTemplateName(e.target.value)}
					/>
					<Button
						fullWidth
						onClick={async () => {
							const { isSuccess } = await onCreateTemplate(templateName)
							if (isSuccess) {
								handleClose()
							}
						}}
						isLoading={isLoadingTemplates}
					>
						Create
					</Button>
				</Stack>
			</Modal>
			<Box
				sx={{
					minWidth: columnWidth,
					maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
					overflow: 'auto',
				}}
			>
				<Stack direction="column" spacing={1} padding={2}>
					<Button fullWidth startIcon={<AddIcon />} onClick={() => setIsCreateTemplateOpen(true)}>
						New Template
					</Button>
					<List>
						{templates.map((template) => {
							return (
								<TemplateListItem
									key={template._id}
									{...template}
									isEditMode={templateOnEditMode === template._id}
									setTemplateOnEditMode={setTemplateOnEditMode}
								/>
							)
						})}
					</List>
				</Stack>
			</Box>
		</>
	)
}
