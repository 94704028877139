import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useContext, useEffect, useState } from 'react'
import { MyTasksV3Sdk } from '@cango-app/sdk'

import { Text, Button, Select, TextField, Grid } from 'src/components'
import { selectors as userSelectors } from 'src/store/modules/user'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import { GENERIC_BLOCKED_REASONS } from 'src/constants/options'

type IssueForm = {
	reportTo: string
	reason: MyTasksV3Sdk.BlockedTaskReason
	description: string
}

export const TaskReport = () => {
	const usersForSelect = useSelector(userSelectors.getAllUsersForSelect)
	const { task, onClose } = useContext(TaskContext)
	const { control, handleSubmit, watch, reset } = useForm<IssueForm>()
	const dispatch = useDispatch()
	const blockOptions = useSelector(projectSelectors.getBlockOptions)
	const isLoadingBlockOptions = useSelector(projectSelectors.getIsLoadingBlockOptions)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [description, reportTo, reason] = watch(['description', 'reportTo', 'reason'])
	const isGenericReason = GENERIC_BLOCKED_REASONS.includes(reason)

	const projectId = task?.project_id

	useEffect(() => {
		if (projectId) {
			dispatch(projectActions.fetchBlockOptions(projectId))
		}
	}, [projectId])

	const onSubmitForm = async (data: IssueForm) => {
		if (!task) return
		setIsSubmitting(true)
		await dispatch(
			projectActions.flagTask({
				taskId: task._id,
				reportTo: data.reportTo,
				description: data.description,
				reason: data.reason,
			}),
		)
		setIsSubmitting(false)
		reset()
		if (onClose) {
			onClose()
		}
	}

	const isSubmitDisabled = isGenericReason
		? ![description, reportTo, reason].every(Boolean)
		: ![description, reason].every(Boolean)
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Text>Report this task</Text>
			</Grid>
			<Grid item xs={12}>
				<Controller
					control={control}
					name="reason"
					rules={{ required: true }}
					render={({ field: { onChange, value } }) => (
						<Select
							label="Why are you blocked?"
							options={blockOptions}
							isLoading={isLoadingBlockOptions}
							onChange={onChange}
							value={value}
						/>
					)}
				/>
			</Grid>
			{isGenericReason || !reason ? (
				<Grid item xs={12}>
					<Controller
						control={control}
						name="reportTo"
						rules={{ required: true }}
						render={({ field: { onChange, value } }) => (
							<Select
								label="Report to"
								options={usersForSelect}
								onChange={onChange}
								value={value}
							/>
						)}
					/>
				</Grid>
			) : (
				<Grid item xs={12}>
					<Text fontSize={14} fontWeight={500} mb={1}>
						Report to
					</Text>
					<Text>Task owner</Text>
				</Grid>
			)}

			<Grid item xs={12}>
				<Controller
					control={control}
					name="description"
					rules={{ required: true }}
					render={({ field: { onChange, value } }) => (
						<TextField
							multiline
							label="Description"
							fullWidth
							onChange={onChange}
							value={value}
							helperText="They'll receive an email, the task will be flagged, and the description will be added to the task inbox"
							containerProps={{ mb: 2 }}
							FormHelperTextProps={{ sx: { ml: 0 } }}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<Button
					variant="contained"
					sx={{ ml: { laptop: 1 }, flex: 1 }}
					onClick={handleSubmit(onSubmitForm)}
					disabled={isSubmitDisabled}
					isLoading={isSubmitting}
				>
					Submit
				</Button>
			</Grid>
		</Grid>
	)
}
