import React, { ComponentType, useState, useContext, useEffect } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'

import { Box, Text, Modal } from 'src/components'

import { DeleteStep } from './delete-step'
import StepForm from './step-form'
import { StepContext, StepProvider } from './step-provider'

type StepModalProps = {
	stepId?: string
	onClose: () => void
	onDeleteStep: (stepId: string) => void
}

const StepModalContent: ComponentType<{
	shouldPromptDelete: boolean
	onClose: () => void
	onDeleteClick: () => void
	onDirtiedFields: (hasDirtied: boolean) => void
}> = ({ shouldPromptDelete, onClose, onDeleteClick, onDirtiedFields }) => {
	const { isLoadingStep, step } = useContext(StepContext)

	if (isLoadingStep) {
		return (
			<Box display="flex" justifyContent="center" mt={10}>
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (!step) {
		return (
			<Box mt={10}>
				<Text variant="h5" textAlign="center">
					Step not found
				</Text>
			</Box>
		)
	}

	if (shouldPromptDelete) {
		return <DeleteStep onCloseDrawer={onClose} />
	}

	return (
		<StepForm
			onDelete={onDeleteClick}
			onCloseDrawer={onClose}
			setDirtiedFields={onDirtiedFields}
			step={step}
		/>
	)
}

const StepModal: ComponentType<StepModalProps> = ({ stepId, onClose, onDeleteStep }) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [dirtiedFields, setDirtiedFields] = useState(false)

	const manualCloseModal = () => {
		if (!dirtiedFields) {
			handleClose()
			return
		}

		if (confirm('Step Modal contains unsaved changes, are you sure you wish to discard them?')) {
			handleClose()
		}
	}

	const handleClose = () => {
		setShouldPromptDelete(false)
		onClose()
	}

	const stepContent = () => {
		if (!stepId) {
			return null
		}

		return (
			<StepProvider stepId={stepId} onDeleteStep={onDeleteStep}>
				<StepModalContent
					shouldPromptDelete={shouldPromptDelete}
					onDeleteClick={() => setShouldPromptDelete(true)}
					onClose={handleClose}
					onDirtiedFields={setDirtiedFields}
				/>
			</StepProvider>
		)
	}

	useEffect(() => {
		if (!stepId && shouldPromptDelete) {
			setShouldPromptDelete(false)
		}
	}, [stepId, shouldPromptDelete])

	return (
		<Modal
			open={!!stepId}
			onClose={manualCloseModal}
			containerStyle={{
				padding: 0,
				height: '70%',
				width: 700,
				maxWidth: '90vw',
				overflow: 'hidden',
			}}
		>
			<>{stepContent()}</>
		</Modal>
	)
}

export default StepModal
