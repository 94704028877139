import React, { ComponentType, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'

import { Box, ContactAndAssignment, FormCard, Text } from 'src/components'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { getActionLabel } from 'src/helpers/labels'
import { CreateContact } from 'src/modules/my-tasks-v3/components/actions/create-contact'
import { FileUpload } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-upload'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-template'
import { TaskContext } from 'src/providers/task-provider'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { TemplateModal } from 'src/modules/my-tasks-v3/components/actions/template-modal'
import { AsyncDispatchType } from 'src/store/types'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'

import { SetResourcesModal } from '../../../../my-tasks-v3/components/actions/set-resources'

export const ActionFields: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task, updateTask } = useContext(TaskContext)
	const taskRoles = useSelector(projectSelectors.getTaskRoles)
	const taskExternals = useSelector(projectSelectors.getTaskExternals)

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignUserToRole({
				userId,
				roleId,
				projectId: task.project_id,
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignContactToExternal({
				projectId: task.project_id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	const createContact = (contactId: string) => {
		if (!task) return
		updateTask('lifecycle.resource', contactId, { updateDb: true })
	}

	const renderAction = (
		actionType: V3BlueprintTypes.ActionEnum,
		action: V3ClientTypes.Project.TaskAction,
	) => {
		if (!task || !project) return null
		switch (actionType) {
			case V3BlueprintTypes.ActionEnum.FileUpload:
				return <FileUpload project={project} task={task} action={action} />
			case V3BlueprintTypes.ActionEnum.FileTemplate:
				return <FileTemplate task={task} project={project} action={action} />
			case V3BlueprintTypes.ActionEnum.Call:
			case V3BlueprintTypes.ActionEnum.Email:
			case V3BlueprintTypes.ActionEnum.Invite:
			case V3BlueprintTypes.ActionEnum.Meeting:
				return (
					<Box>
						<ContactAndAssignment
							taskExternals={taskExternals}
							taskRoles={taskRoles}
							onAssignExternal={handleExternalAssignChange}
							onAssignRole={handleRoleAssignChange}
						/>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Software:
				return <Software links={action.links} />
			case V3BlueprintTypes.ActionEnum.Video: {
				return <VideoEmbed urls={action.links} />
			}
			case V3BlueprintTypes.ActionEnum.TaskReference:
				return <TaskReference taskReferences={action.task_references} project={project} />
			case V3BlueprintTypes.ActionEnum.Note:
				return (
					<Box sx={{ overflowWrap: 'break-word' }}>
						<Text fontSize={14}>{action.note}</Text>
					</Box>
				)
			case V3BlueprintTypes.ActionEnum.Contact:
				return <CreateContact task={task} useDrawer onCreateContact={createContact} />
			case V3BlueprintTypes.ActionEnum.SetResources:
				return <SetResourcesModal action={action} />
			case V3BlueprintTypes.ActionEnum.Template:
				return <TemplateModal action={action} />
			case V3BlueprintTypes.ActionEnum.Questionaire:
				return (
					<QuestionFlowLogicContainer
						filters={{
							...task.chain?.database_chain_logic?.filters,
							...action.questionaire?.filters,
							items: [
								...(task.chain?.database_chain_logic?.filters?.items ?? []),
								...(action.questionaire?.filters.items ?? []),
							],
						}}
						acceptedQuestionColumns={action.questionaire?.columns ?? []}
						questionScope={action.questionaire?.scope ?? []}
					/>
				)
		}
	}

	if (!task || !task.actions.length) {
		return null
	}

	return (
		<>
			{task.actions.map((_action) => {
				if (_action.type === V3BlueprintTypes.ActionEnum.None) {
					return null
				}
				return (
					<FormCard
						key={_action._id}
						title={`Action - ${getActionLabel(_action.type)}`}
						mt={2}
						mb={2}
					>
						{renderAction(_action.type, _action)}
					</FormCard>
				)
			})}
		</>
	)
}
