import { V3BlueprintSdk } from '@cango-app/sdk'
import { Stack } from '@mui/material'
import React, { ComponentType } from 'react'
import { FieldErrors } from 'react-hook-form'

import { Button } from 'src/components'

type SubmitButtonsProps = {
	onSubmit: () => void
	onDelete: () => void
	isLoading?: boolean
	stepId?: string
	canSubmit: boolean
	errors: FieldErrors<V3BlueprintSdk.UnpopulatedStep>
}

export const SubmitButtons: ComponentType<SubmitButtonsProps> = ({
	onSubmit,
	onDelete,
	isLoading,
	stepId,
	canSubmit,
}) => (
	<Stack direction="row" mt={3} spacing={2}>
		<Button
			variant="contained"
			fullWidth
			onClick={onSubmit}
			isLoading={isLoading}
			disabled={!canSubmit}
		>
			Save
		</Button>
		{!!stepId && (
			<Button color="error" fullWidth onClick={onDelete} disabled={isLoading} variant="outlined">
				Delete
			</Button>
		)}
	</Stack>
)
