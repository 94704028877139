import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import React, { ComponentType, useContext } from 'react'
import { useSelector } from 'react-redux'

import { Box, FormCard } from 'src/components'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { getActionLabel } from 'src/helpers/labels'
import { TaskContext } from 'src/providers/task-provider'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'

import { FileTemplate } from './file-tasks/file-template'
import { FileUpload } from './file-tasks/file-upload'
import { VideoEmbed } from './video-embed'
import { CreateContact } from './create-contact'
import { Note } from './note'
import { ContactInfo } from './contact-info'
import { Archive } from './archive'
import { Software } from './software'
import { TaskReference } from './task-reference'
import { SetResources } from './set-resources'

const Action: ComponentType<{ action: V3ClientTypes.Project.TaskAction }> = ({ action }) => {
	const { task } = useContext(TaskContext)
	const project = useSelector(projectSelectors.getSelectedProject)
	if (!task || !project) return null

	switch (action.type) {
		case V3BlueprintTypes.ActionEnum.FileTemplate:
			return <FileTemplate task={task} project={project} action={action} />
		case V3BlueprintTypes.ActionEnum.Archive:
			return <Archive task={task} />
		case V3BlueprintTypes.ActionEnum.Invite:
		case V3BlueprintTypes.ActionEnum.Meeting:
		case V3BlueprintTypes.ActionEnum.Call:
		case V3BlueprintTypes.ActionEnum.Email:
			return <ContactInfo project={project} />
		case V3BlueprintTypes.ActionEnum.Contact:
			return <CreateContact task={task} />
		case V3BlueprintTypes.ActionEnum.FileUpload:
			return <FileUpload project={project} task={task} action={action} />
		case V3BlueprintTypes.ActionEnum.Note:
			return <Note note={action.note} />
		case V3BlueprintTypes.ActionEnum.Software:
			return <Software links={action.links} />
		case V3BlueprintTypes.ActionEnum.Video:
			return <VideoEmbed urls={action.links} />
		case V3BlueprintTypes.ActionEnum.SetResources:
			return <SetResources action={action} />
		case V3BlueprintTypes.ActionEnum.TaskReference:
			return <TaskReference taskReferences={action.task_references} project={project} />
		case V3BlueprintTypes.ActionEnum.Questionaire:
			return (
				<QuestionFlowLogicContainer
					filters={{
						...task.chain?.database_chain_logic?.filters,
						...action.questionaire?.filters,
						items: [
							...(task.chain?.database_chain_logic?.filters?.items ?? []),
							...(action.questionaire?.filters.items ?? []),
						],
					}}
					acceptedQuestionColumns={action.questionaire?.columns ?? []}
					questionScope={action.questionaire?.scope ?? []}
				/>
			)
	}
}

export const TaskAction: ComponentType = () => {
	const { task } = useContext(TaskContext)

	if (!task) return null

	return (
		<Box display="flex" justifyContent="center" mb={2}>
			<Box width="100%" pt={3} minHeight={100}>
				{/* ===== Action goes below here ===== */}
				{task.actions.map((action) => {
					if (action.type === V3BlueprintTypes.ActionEnum.None) {
						return null
					}
					return (
						<FormCard key={action._id} title={getActionLabel(action.type)} mb={2}>
							<Action action={action} />
						</FormCard>
					)
				})}
			</Box>
		</Box>
	)
}
