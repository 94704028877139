import React, { ComponentType, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Checkbox, Divider } from 'src/components'

import { ChainContext } from '../../chain-provider'

import { DependencyForm } from './types'

export const MultiUseConfig: ComponentType<{ target: string }> = ({ target }) => {
	const { control, watch } = useFormContext<DependencyForm>()
	const { nodeMap } = useContext(ChainContext)
	const multiUseConfig = watch('multiUseConfig')
	const targetNode = nodeMap.get(target)

	if (!targetNode || !multiUseConfig) {
		return null
	}

	return (
		<>
			<Divider sx={{ py: 1 }} />
			<Box my={1}>
				<Controller
					control={control}
					name="multiUseConfig.is_last_point_to_return"
					render={({ field }) => (
						<Checkbox
							label={`This is the last point you can return to: ${targetNode.data.name}`}
							checked={!!field.value}
							onChange={field.onChange}
						/>
					)}
				/>
			</Box>
		</>
	)
}
