import { ComponentType, useContext } from 'react'
import { useSelector } from 'react-redux'
import { V3ClientTypes } from '@cango-app/types'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import {
	CompleteTaskButton,
	OnPostCompleteTaskProps,
} from 'src/modules/my-tasks-v3/components/complete-task-button'
import { useProject } from 'src/modules/projects-v3/useProject'

export const CompleteTaskOps: ComponentType = () => {
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task } = useContext(TaskContext)
	const projectTasks = useSelector(projectSelectors.getMappedProjectTasks)
	const { goToTask } = useProject()

	const handlePostTaskComplete = (data: OnPostCompleteTaskProps) => {
		if (data.payload.goToTask && task) {
			const getParentTasks = (
				_task: V3ClientTypes.Project.Task,
				visitedTasks = new Set<string>(),
			): V3ClientTypes.Project.Task[] => {
				if (visitedTasks.has(_task._id)) {
					return []
				}
				visitedTasks.add(_task._id)
				if (!_task.parent) {
					return []
				}
				const parentTask = projectTasks.get(_task.parent._id)
				if (!parentTask) {
					return []
				}

				const parentTasks: V3ClientTypes.Project.Task[] = [parentTask]
				if (parentTask.parent) {
					const parentOfParentTasks = getParentTasks(parentTask, visitedTasks)
					parentTasks.push(...parentOfParentTasks)
				}

				return parentTasks
			}
			const parentTasks = getParentTasks(task)
			const taskToGoTo = parentTasks.find((_task) => _task.step?._id === data.payload.goToTask)
			goToTask(taskToGoTo?._id)
			return
		}
		goToTask(undefined)
	}

	if (!task) {
		return null
	}

	if (project?.archived.state) {
		return null
	}

	return <CompleteTaskButton task={task} onPostComplete={handlePostTaskComplete} />
}
