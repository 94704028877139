import React, { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import PulseLoader from 'react-spinners/PulseLoader'
import _isEqual from 'lodash/isEqual'

import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { TableContext } from 'src/providers/table-provider'
import { Box, Checkbox, Select, Text } from 'src/components'
import { ChainChip } from 'src/components/section-tasks-v3/chains-list'
import { TableFilters } from 'src/components/table-filters'

import { getFilters } from '../../../tables/utils'

import { DependencyForm } from './types'

export const DependencyFromDatabase: ComponentType = () => {
	const { table, isLoadingTable, mappedColumns } = useContext(TableContext)
	const { control, setValue, watch } = useFormContext<DependencyForm>()
	const [firstFilterRender, setFirstFilterRender] = useState<GridFilterModel>()
	const databaseLogic: DependencyForm['databaseLogic'] = watch('databaseLogic')
	const databaseLogicColumn = databaseLogic?.column ?? ''
	const databaseFilters: GridFilterModel = databaseLogic?.filters ?? { items: [] }

	const mappedContacts = useSelector(contactSelectors.mappedContacts)

	const chainPrefix = useMemo(() => {
		return mappedColumns.get(databaseLogicColumn)?.name ?? ''
	}, [databaseLogicColumn, mappedColumns])

	const columnSelectors = useMemo(() => {
		if (!table?.fields.length) {
			return []
		}

		return table.fields.map((_field) => ({
			_id: _field._id,
			label: _field.name,
		}))
	}, [table?.fields])

	const exampleValue = useMemo(() => {
		if (!databaseLogicColumn || !table?.records.length || !columnSelectors.length) {
			return ''
		}

		const record = table.records.find((_record) => _record.data[databaseLogicColumn])

		if (!record) {
			return ''
		}

		const column = mappedColumns.get(databaseLogicColumn)

		if (!column) {
			return ''
		}

		const recordValue = record.data[databaseLogicColumn]

		if (column.type === TableTypes.FieldType.CONTACT && typeof recordValue === 'string') {
			const contact = mappedContacts.get(recordValue)
			if (contact) {
				return `${contact.name} ${contact.surname}`
			}
		}

		const valueOption = column.valueOptions?.find((_option) => _option._id === recordValue)

		if (valueOption) {
			return valueOption.label
		}

		return String(recordValue)
	}, [columnSelectors, table, databaseLogicColumn])

	const handleSetFilters = useCallback(
		(filters: GridFilterModel) => {
			if (!firstFilterRender && !filters.items.length && databaseFilters.items.length) {
				setFirstFilterRender(filters)
				return
			}

			if (!firstFilterRender) {
				setFirstFilterRender(filters)
			}

			if (_isEqual(databaseFilters, filters)) {
				return
			}

			setValue('databaseLogic.filters', filters)
		},
		[databaseFilters, firstFilterRender],
	)

	if (isLoadingTable) {
		return <PulseLoader size={6} />
	}

	return (
		<Box pt={2}>
			<Controller
				control={control}
				name="databaseLogic"
				render={({ field: { value, onChange } }) => (
					<Checkbox
						label={'Use database logic from a "Set Resources" action to define chains'}
						checked={!!value}
						onChange={(e, isChecked) => {
							setValue('chainPrefix', '')
							if (isChecked) {
								onChange({
									column: '',
									filters: { items: [] },
								})
								return
							}
							onChange(null)
						}}
					/>
				)}
			/>
			{!!databaseLogic && (
				<Box ml={3}>
					<TableFilters
						initialState={{
							filter: {
								filterModel: {
									...databaseFilters,
									items: getFilters({ table, filters: databaseFilters.items, mappedColumns }),
								},
							},
						}}
						onChange={handleSetFilters}
					/>
					<Controller
						control={control}
						name="databaseLogic.column"
						render={({ field: { value, onChange } }) => {
							const handleColumnSelectionChange = (newColumnId: string) => {
								setValue(
									'chainPrefix',
									columnSelectors.find((_column) => _column._id === newColumnId)?.label ?? '',
								)
								onChange(newColumnId)
							}

							return (
								<Select
									options={columnSelectors}
									disableOrdering
									containerProps={{ my: 1 }}
									label="Column"
									onChange={(e) => handleColumnSelectionChange(e.target.value as string)}
									value={value}
									helperText={
										<Box>
											<Text
												fontSize={12}
											>{`Tags will be constructed according to the column name`}</Text>
											<Box display="flex" alignItems="center">
												<Text fontSize={12} mr={1}>
													{'e.g.'}
												</Text>
												<ChainChip
													prefix={chainPrefix || '[example]'}
													selected_option={exampleValue || 'Example option 1'}
													isLoadingTable={false}
													color={'#c4def6'}
													columnType={mappedColumns.get(databaseLogicColumn)?.type}
												/>
											</Box>
										</Box>
									}
								/>
							)
						}}
					/>
				</Box>
			)}
		</Box>
	)
}
