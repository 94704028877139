import { ComponentType, useContext, MouseEvent } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import Stack from '@mui/material/Stack'

import { Box, IconButton, Text } from 'src/components'

import { PlusIcon } from '../../../assets/icons'
import { TableContext } from '../../../providers/table-provider'

import { BasicQuestionaireData } from './types'

export const QuestionNode: ComponentType<NodeProps<BasicQuestionaireData>> = ({ id, data }) => {
	const { onAddRow, isUpdatingTable } = useContext(TableContext)

	const handleAddQuestionClick = (event: MouseEvent) => {
		event.stopPropagation()
		onAddRow({ parentId: id })
	}

	return (
		<Box
			sx={{
				minWidth: 170,
				maxWidth: 200,
				py: 1,
				px: 2,
				borderRadius: 1,
				position: 'relative',
				bgcolor: '#fff',
				border: '1px solid #e0e0e0',
			}}
		>
			<Handle type="target" position={Position.Left} />
			<Stack direction="row">
				<Box flex={1}>
					<Text>{data.label}</Text>
				</Box>
				<Box>
					<IconButton size="small" onClick={handleAddQuestionClick} isLoading={isUpdatingTable}>
						<PlusIcon width={16} />
					</IconButton>
				</Box>
			</Stack>

			<Handle type="source" position={Position.Right} />
		</Box>
	)
}
