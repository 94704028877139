import { TableTypes, V3ClientTypes } from '@cango-app/types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import juice from 'juice'
import { Page, Document, usePDF, Font } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import { PulseLoader } from 'react-spinners'

import { Modal, Box, Button, TipTapContentWithStyles } from 'src/components'
import { useResolveTemplate } from 'src/hooks/useResolveTemplate'
import { TableContext } from 'src/providers/table-provider'
import { resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { useColumnFormatter } from 'src/modules/tables/use-column-formatter'
import { useRichEditor, EditorProvider } from 'src/modules/templates-editor/useRichEditor'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { EditorOptions } from 'src/modules/templates-editor/editor-options'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

const EditorComponent = ({ templateName }: { templateName: string }) => {
	const { editor } = useRichEditor()
	const [instance, setInstance] = usePDF()

	const getContentWithStyles = useCallback(async () => {
		if (!editor) return ''
		const htmlContent = editor.getHTML()

		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return ''
		}
		const styles = await response.text()

		return `
        <style>${styles}</style>
      <div class="tableWrapper">
      ${htmlContent}</div>
    `
	}, [editor])

	const onCopyContent = useCallback(async () => {
		const fullHtmlContent = await getContentWithStyles()
		const htmlWithInlineStyles = juice(fullHtmlContent)
		const blob = new Blob([htmlWithInlineStyles], { type: 'text/html' })
		const clipboardItem = new ClipboardItem({ 'text/html': blob })
		navigator.clipboard
			.write([clipboardItem])
			.then(() => {
				showSnackbar('Template copied!', { variant: 'success' })
			})
			.catch(() => {
				showSnackbar('Failed to copy template', { variant: 'error' })
			})
	}, [getContentWithStyles])

	if (!editor) return null

	useEffect(() => {
		if (instance.blob) {
			const link = document.createElement('a')
			link.href = URL.createObjectURL(instance.blob)
			link.download = `${templateName}.pdf`
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}, [instance])

	const handleDownloadPDF = useCallback(async () => {
		const response = await fetch('/rich-editor-styles.css')

		if (!response.ok) {
			showSnackbar('Error getting table styles', { variant: 'error' })
			return
		}
		const styles = await response.text()

		const html = `
      <html>
        <body>
          <style>${styles}</style>
          <div class="tableWrapper template">${editor?.getHTML()}</div>
        </body>
      </html>`

		const newContent = (
			<Document>
				<Page size="LETTER" style={{ padding: 20 }}>
					<Html resetStyles={true}>{html}</Html>
				</Page>
			</Document>
		)
		setInstance(newContent)
	}, [editor])
	return (
		<Box width="70vw">
			<EditorOptions />
			<TipTapContentWithStyles editor={editor} />
			<Stack marginTop={2} width="450px" direction="row" spacing={2}>
				<Button onClick={onCopyContent}>Copy Template</Button>
				<Button onClick={handleDownloadPDF}>Download PDF</Button>
			</Stack>
		</Box>
	)
}

type Props = {
	action: V3ClientTypes.Project.TaskAction
}
export const TemplateModal = ({ action }: Props) => {
	const [open, setOpen] = useState(false)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)

	const handleCloseModal = () => {
		setOpen(false)
	}

	const { apiRef, table } = useContext(TableContext)

	const rows = useMemo(() => {
		if (!table) return []
		return table.records.map((_record) => {
			const resolvedRow = resolveAnyRowCalculations({
				fields: table.fields,
				row: _record,
				referenceColumns: table.referenceColumnNames,
				questionaireAnswers: table.questionaire_answers ?? [],
			})
			table.fields.forEach(({ type, _id, valueOptions }) => {
				if (
					[
						TableTypes.FieldType.TABLE_SELECT,
						TableTypes.FieldType.SINGLE_SELECT,
						TableTypes.FieldType.REFERENCE,
						TableTypes.FieldType.CONTACT,
					].includes(type)
				) {
					resolvedRow[_id] =
						valueOptions.find((valueOption) => valueOption._id === resolvedRow[_id])?.label ?? ''
				}
			})
			return resolvedRow
		})
	}, [table, mappedContacts])

	const { content } = useResolveTemplate({ template: action.template })
	const { columns } = useColumnFormatter({
		apiRef,
		isBulkEditEnabled: false,
		isTableLocked: true,
		showIcons: false,
		sortingModel: [],
	})

	if (!action.template || !content) return null
	if (!table) return <PulseLoader />
	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				getRowId={(row) => row._id}
				columns={columns}
				rows={rows}
				sx={{
					display: 'none',
				}}
			/>
			<Modal
				open
				onClose={handleCloseModal}
				sx={{
					display: open ? 'flex' : 'none',
				}}
			>
				<EditorProvider defaultContent={content}>
					<EditorComponent templateName={action.template.template.name} />
				</EditorProvider>
			</Modal>
			<Button variant="outlined" onClick={() => setOpen(true)} disabled={!table}>
				Open Template
			</Button>
		</>
	)
}
