import { Stack } from '@mui/material'
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined'
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import { useFormContext } from 'react-hook-form'
import { TemplateTypes } from '@cango-app/types'
import { ReactNode } from 'react'

import { Button } from 'src/components'
import { TrashIcon } from 'src/assets/icons'

import { useRichEditor } from './useRichEditor'
import { EditorOptions } from './editor-options'

const getIcon = (type: TemplateTypes.VariableType): ReactNode => {
	switch (type) {
		case TemplateTypes.VariableType.text:
			return <TextFieldsOutlinedIcon />
		case TemplateTypes.VariableType.view:
			return <FunctionsOutlinedIcon />
		case TemplateTypes.VariableType.chain:
			return <ChecklistOutlinedIcon />
		case TemplateTypes.VariableType.contacts:
			return <ContactPageOutlinedIcon />
	}
}

export const EditorTools = () => {
	const { editor } = useRichEditor()
	const { watch } = useFormContext<TemplateTypes.Template>()
	const variables = watch('variables')

	if (!editor) return null

	const onAddingVariable = (variableName: string) => {
		editor
			.chain()
			.command(({ tr, commands }) => {
				const placeholder = `{{${variableName}}}`
				const { $to } = tr.selection
				const to = tr.mapping.map($to.pos)

				return tr.insertText(placeholder) && commands.focus(to)
			})
			.run()
	}
	return (
		<Stack direction="row" justifyContent="space-between" marginBottom={3}>
			<Stack
				direction="row"
				alignItems="center"
				sx={{
					display: 'flex',
					overflowY: 'hidden',
					flexWrap: 'wrap',
					maxWidth: '70%',
				}}
			>
				<EditorOptions />
				{variables.map(({ _id, name, type }) => {
					return (
						<Button
							key={_id}
							sx={{
								minWidth: 'fit-content',
								maxWidth: 'fit-content',
								marginX: 1,
							}}
							variant="text"
							startIcon={getIcon(type)}
							onClick={() => onAddingVariable(name)}
						>
							{name ?? ''}
						</Button>
					)
				})}
			</Stack>
			<Stack direction="row" spacing={1}>
				<Button
					variant="text"
					startIcon={<TrashIcon />}
					onClick={() => {
						editor.commands.clearContent()
					}}
				>
					Clear all
				</Button>
			</Stack>
		</Stack>
	)
}
